<template>
  <div>
    <div v-if="ready" class="container">
      <div class="py-5 text-center">
        <img
          class="d-block mx-auto mb-4"
          :src="logo"
          alt=""
          width="20%"
          height="20%"
        />
        <h2>{{ data.pageContent[0].title }}</h2>
        <p class="lead">{{ data.pageContent[0].subtitle }}.</p>
        <p class="lead">{{ data.pageContent[0].description }}.</p>
      </div>

      <div v-if="hasClient">
        <form-wizard
          class="form-wizard-steps"
          :color="wizard.color"
          :errorColor="'#df2525'"
          :title="''"
          :subtitle="''"
          :nextButtonText="'Próximo'"
          :backButtonText="'Voltar'"
          :finishButtonText="'Confirmar Reserva'"
          :startIndex="wizard.index"
          :stepSize="isDesktop ? 'md' : 'xs'"
          @on-complete="submit"
        >
          <hr class="mb-4" />
          <tab-content
            :title="isDesktop ? 'Reserva' : ''"
            :beforeChange="checkReservas"
          >
            <Reserva ref="rematricula_reserva" />
          </tab-content>
          <tab-content
            :title="isDesktop ? 'Dados da Criança' : ''"
            :beforeChange="checkDadosCrianca"
          >
            <DadosCrianca ref="dadosCrianca" />
          </tab-content>
          <tab-content
            :title="isDesktop ? 'Responsavel Financeiro' : ''"
            :beforeChange="checkDadosFinanceiro"
          >
            <DadosFinanceiro ref="dadosFinanceiro" :parent_data="model" />
          </tab-content>
          <tab-content
            :title="isDesktop ? 'Responsável Legal' : ''"
            :beforeChange="checkDadosLegal"
          >
            <DadosLegal ref="dadosLegal" :parent_data="model" />
          </tab-content>
          <tab-content :title="isDesktop ? 'Termos e Condições' : ''">
            <div class="custom-control custom-checkbox">
              <input
                v-model="model.accept_terms"
                type="checkbox"
                class="custom-control-input"
                id="terms"
              />
              <label class="custom-control-label" for="terms"
                >Li e concordo com a
                <a :href="link_politica" target="_blank"
                  >política de privacidade</a
                >.</label
              >
            </div>
            <div class="custom-control custom-checkbox">
              <input
                v-model="model.authorize_share_data"
                type="checkbox"
                class="custom-control-input"
                id="share"
              />
              <label class="custom-control-label" for="share"
                >Eu autorizo o compartilhamento de dados</label
              >
            </div>
            <b-card
              class="my-4 content-footer"
              v-if="data.pageContent[0].content_footer"
              v-html="data.pageContent[0].content_footer"
            >
            </b-card>
          </tab-content>
          <hr class="mb-4" />
          <div slot="finish">
            <b-button
              v-if="!submiting"
              variant="primary"
              style="font-size: 14px; font-weight: 600"
              >Confirmar Reserva</b-button
            >
            <b-button
              v-else
              variant="primary"
              style="font-size: 14px; font-weight: 600"
              disabled
              >Confirmar Reserva <b-spinner small></b-spinner
            ></b-button>
          </div>
        </form-wizard>
      </div>
      <div v-else>nenhuma empresa</div>

      <footer class="my-5 pt-5 text-muted text-center text-small">
        <p class="mb-1">
          &copy; {{currentYear}} - {{data.unity[0].commercial_name || ''}} - Todos os direitos reservados.
        </p>
      </footer>

      <sweet-modal ref="modalError" icon="error"> erro </sweet-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import moment from "moment";

export default {
  data() {
    return {
      ready: false,
      submiting: false,
      wizard: {
        index: 0,
        color: "#007bff",
        stepSize: "xs",
      },
      hasClient: false,
      model: {
        application_id: process.env.VUE_APP_APPLICATION_ID,
        unity_id: "",
        email: "",
        cell_phone: "",
        reserve: {
          group_id: "",
          period_id: "",
          plan_id: "",
          current_year: "",
        },
        client: {
          name: "",
          cpf: "",
          rg: "",
          birthday: "",
          nationality: "",
          home_address: {
            zip_code: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            state: "",
            city: "",
          },
        },
        financial_data: {
          name: "",
          cpf: "",
          rg: "",
          birthday: "",
          nationality: "",
          marital_state: "",
          home_address: {
            zip_code: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            state: "",
            city: "",
          },
          business_address: {
            zip_code: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            state: "",
            city: "",
          },
          contact: {
            home_phone: "",
            business_phone: "",
            cell_phone: "",
            email: "",
            relationship: "",
          },
        },
        legal_data: {
          name: "",
          cpf: "",
          rg: "",
          birthday: "",
          nationality: "",
          marital_state: "",
          home_address: {
            zip_code: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            state: "",
            city: "",
          },
          business_address: {
            zip_code: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            state: "",
            city: "",
          },
          contact: {
            home_phone: "",
            business_phone: "",
            cell_phone: "",
            email: "",
            relationship: "",
          },
        },
        accept_terms: false,
        authorize_share_data: false,
      },
      show: true,
    };
  },
  components: {
    FormWizard,
    TabContent,
    DadosCrianca: () => import("./components/DadosCrianca.vue"),
    DadosFinanceiro: () => import("./components/DadosFinanceiro.vue"),
    DadosLegal: () => import("./components/DadosLegal.vue"),
    Reserva: () => import("./components/Reserva.vue"),
  },
  computed: {
    link_politica(){
      return `/rematricula/${this.model.unity_id}/politica_de_privacidade`
    },
    logo(){
      return `${process.env.VUE_APP_PREFIX_LOGO}/logo/${this.data.pageContent[0].unity_id}/logo.png`
    },
    isDesktop() {
      if (navigator.userAgentData.mobile) {
        return false;
      } else {
        return true;
      }
    },
    groups() {
      return this.$store.state.reEnrolls.groups;
    },
    periods() {
      return this.$store.state.reEnrolls.periods;
    },
    estados() {
      return this.$store.state.reEnrolls.estados;
    },
    data() {
      return this.$store.state.reEnrolls.data;
    },
    currentYear(){
      return moment().year()
    }
  },

  methods: {
    check() {
      console.log(this.model);
    },
    change(v) {
      console.log("root", v);
    },
    makeToast(text) {
      this.$bvToast.toast(text, {
        title: "ATENÇÃO",
        variant: "danger",
        toaster: "b-toaster-top-center",
        solid: true,
      });
    },
    checkReservas() {
      let reserve = this.$refs.rematricula_reserva.getModel();

      if (reserve.group_id == "" || reserve.period_id == "") {
        this.makeToast("Favor selecionar uma turma e um periodo");

        return false;
      } else {
        this.model.reserve = { ...reserve };

        return true;
      }
    },
    checkDadosCrianca() {
      const checkErrors = this.$refs.dadosCrianca.checkRequired();

      if (checkErrors) {
        this.model.client = { ...this.$refs.dadosCrianca.getModel() };
      }

      return checkErrors;
    },
    checkDadosFinanceiro() {
      const noErrors = this.$refs.dadosFinanceiro.checkRequired();

      if (noErrors) {
        const el = this.$el.getElementsByClassName("form-wizard-steps")[0];

        Object.assign(
          this.model.financial_data,
          this.$refs.dadosFinanceiro.getModel()
        );

        if (el) {
          el.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        setTimeout(() => {
          const el = this.$el.getElementsByClassName("input-error")[0];

          if (el) {
            el.parentNode.scrollIntoView({ behavior: "smooth" });
          }
        }, 200);
      }

      return noErrors;
    },
    checkDadosLegal() {
      const noErrors = this.$refs.dadosLegal.checkRequired();

      if (!noErrors) {
        setTimeout(() => {
          const el = this.$el.getElementsByClassName("input-error")[0];

          if (el) {
            el.parentNode.scrollIntoView({ behavior: "smooth" });
          }
        }, 100);
      } else {
        this.model.legal_data = { ...this.$refs.dadosLegal.getModel() };
      }

      return noErrors;
    },
    crianca_cep(event) {
      if (String(event.target.value).length > 8) {
        this.model.client.cep = String(this.model.client.cep).slice(0, -1);
      } else {
        if (String(event.target.value).length == 8) {
          axios
            .get(`https://viacep.com.br/ws/${this.model.client.cep}/json/`)
            .then((res) => {
              this.model.client.endereco = res.data.logradouro;
              this.model.client.bairro = res.data.bairro;
              this.model.client.state = res.data.uf;
            });
        }
      }
    },
    async submit() {
      if (this.model.accept_terms !== false) {
        this.submiting = true;
        const normalizedBirth = moment(
          this.model.client.birthday,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");

        this.model.client.birthday = normalizedBirth;

        const normalizedFinancialBirth = moment(
          this.model.financial_data.birthday,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");

        this.model.financial_data.birthday = normalizedFinancialBirth;

        const normalizedLegalBirth = moment(
          this.model.legal_data.birthday,
          "DD-MM-YYYY"
        ).format("YYYY-MM-DD");

        this.model.legal_data.birthday = normalizedLegalBirth;

        const response = await this.$store.dispatch(
          "reEnrolls/saveForm",
          this.model
        );
        this.submiting = false;
        if (response.data.status == 201) {
          this.$router.push(`/confirmar-reserva/${response.data.payload.id}`);
        }
      } else {
        this.makeToast("Você precisa aceitar os termos do contrato");
      }
    },
  },
  async mounted() {
    const unityId = this.$route.params.unityId;

    if (unityId) {
      this.model.unity_id = unityId;

      const response = await this.$store.dispatch("reEnrolls/initApp", unityId);

      console.log(response)

      if (response) {
        this.hasClient = true;
        this.ready = true;
      }
    }
  },
};
</script>
<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.content-footer {
  padding-top: 1rem;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>